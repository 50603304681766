import React, { useCallback, useContext, useState } from "react";

import RewardEntry from "../components/redeem/RewardEntry";
import { Reward } from "../../data/rewards";
import RewardPopup from "../components/redeem/RewardPopup";
import { UserContext } from "src/contexts/User.context";

enum BadgeLabelOptions {
  BLOCKED = "LOCKED  🔒",
  LIMIT_REACHED = "LIMIT REACHED",
  SOLD_OUT = "SOLD OUT",
  CLAIM = "CLAIM",
}

export const RedeemScreen: React.FC = () => {
  const { rewardData, currentEmployee } = useContext(UserContext);
  const numberOfRedeemableGifts = currentEmployee?.redeemableBricks ?? 0;

  const [selectedReward, setSelectedReward] = useState<Reward | null>(null);

  const handleRewardClick = useCallback((reward: Reward) => {
    setSelectedReward(reward);
  }, []);

  const handleClose = useCallback(() => {
    setSelectedReward(null);
  }, []);

  if (numberOfRedeemableGifts === undefined) return null;

  const getClaimBadgeLabel = (reward: Reward) => {
    if (reward.limit === 0) return BadgeLabelOptions.LIMIT_REACHED;
    if (reward.supply === 0) return BadgeLabelOptions.SOLD_OUT;

    if (numberOfRedeemableGifts < reward.bricksRequired) {
      return BadgeLabelOptions.BLOCKED;
    }
    if (reward.limit === 0) return BadgeLabelOptions.LIMIT_REACHED;
    if (reward.supply === 0) return BadgeLabelOptions.SOLD_OUT;
    return BadgeLabelOptions.CLAIM;
  };

  const isDisabled = (reward: Reward) => {
    return (
      numberOfRedeemableGifts < reward.bricksRequired ||
      reward.limit === 0 ||
      reward.supply === 0
    );
  };

  return (
    <div className="h-full  w-full pt-[150px] overflow-scroll text-white  px-[40px] md:px-[80px] lg:max-w-[2000px] lg:px-[15%]">
      <div className="flex flex-row flex-wrap items-center w-full justify-between mb-[20px]   gap-[20px]   ">
        <div className="flex flex-row flex-wrap  items-center justify-between w-full">
          <h1 className="font-pressura_text  uppercase text-[40px] pr-[40px]">
            Redeem Rewards
          </h1>
          <div className="my-[20px] md:my-0 w-[320px] text-[16px] font-bold py-[8px]   font-primary  uppercase text-center text-white flex flex-row flex-wrap items-center justify-center rounded-xl  border-[3px] backdrop-blur">
            <p>{`YOU HAVE ${numberOfRedeemableGifts} BRICK${
              numberOfRedeemableGifts === 1 ? "" : "S"
            }`}</p>
          </div>
        </div>
        <p className="-mt-[8px] mb-[12px] md:w-[60%] text-[16px] leading-[20px] font-light font-primary">
          Redeem your Bricks for exclusive rewards, and enjoy the results of
          your awesome work. It's not easy to build the future!
        </p>
        <div className="w-full pb-[100px]">
          {rewardData.map(reward => (
            <RewardEntry
              key={reward.idx}
              reward={reward}
              onClickEntry={() => handleRewardClick(reward)}
              claimDisabled={isDisabled(reward)}
              claimBadgeLabel={getClaimBadgeLabel(reward)}
            />
          ))}
        </div>
        {selectedReward && (
          <RewardPopup
            reward={selectedReward}
            onClose={handleClose}
            claimDisabled={isDisabled(selectedReward)}
            claimBadgeLabel={getClaimBadgeLabel(selectedReward)}
          />
        )}
      </div>
    </div>
  );
};
