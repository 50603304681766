import { ConnectKitButton } from "connectkit";

export const ConnectWalletButton: React.FC = () => {
  return (
    <div className="absolute z-10 right-[10%] top-[40px]">
      <ConnectKitButton
        customTheme={{
          "--ck-font-family": '"FAIR-XYZ"',
        }}
        theme="midnight"
      />
    </div>
  );
};
