import { readContract } from "@wagmi/core";
import { BRICKS_CONTRACT_ADDRESS } from "./contract.constants";
import JSONABI from "../abi/FairxyzGifts.json";
const abi = JSONABI.abi;

export const getRemainingGifts = async (
  address: string | undefined,
): Promise<number> => {
  if (!address) return 0;
  try {
    const data = await readContract({
      addressOrName: BRICKS_CONTRACT_ADDRESS,
      contractInterface: abi,
      functionName: "viewGiftsRemaining",
      args: [address],
    });
    return Number(data.giftsLeft);
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getReceivedGifts = async (
  address: string | undefined,
): Promise<number> => {
  if (!address) return 0;
  try {
    const data = await readContract({
      addressOrName: BRICKS_CONTRACT_ADDRESS,
      contractInterface: abi,
      functionName: "giftsReceived",
      args: [address],
    });
    return Number(data);
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const getNumberOfRedeemableGifts = async (
  address: string | undefined,
): Promise<number> => {
  if (!address) return 0;
  try {
    const data = await readContract({
      addressOrName: BRICKS_CONTRACT_ADDRESS,
      contractInterface: abi,
      functionName: "balanceOf",
      args: [address, 1],
    });
    return Number(data);
  } catch (e) {
    console.log(e);
    return 0;
  }
};

export const viewRewardsClaimed = async (
  address: string | undefined,
  rewardId: string | null,
): Promise<
  { userClaimed: number | null; totalClaimed: number | null } | undefined
> => {
  if (!address) return;
  try {
    const data = await readContract({
      addressOrName: BRICKS_CONTRACT_ADDRESS,
      contractInterface: abi,
      functionName: "viewRewardsClaimed",
      args: [address, rewardId],
    });
    return {
      userClaimed: Number(data[0].claimCount),
      totalClaimed: Number(data[1]),
    };
  } catch (e) {
    console.log(e);
  }
};
