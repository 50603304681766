import React from "react";

type BadgeType = "normal" | "new" | "limited" | "white";

interface RewardBadgeProps {
  type: BadgeType;
  text: string;
}

const RewardBadge: React.FC<RewardBadgeProps> = ({ type, text }) => {
  const typeStyles: { [key in BadgeType]: string } = {
    normal: "border border-white text-white",
    new: "bg-yellow-400 font-bold  text-[#353434]",
    white: "bg-white font-bold  text-black",
    limited: "bg-red-400 font-bold  text-white",
  };

  const badgeStyles = `rounded-lg text-center uppercase font-primary text-[12px] px-[12px] py-[4px] box-border flex items-center justify-center ${typeStyles[type]}`;

  return <div className={badgeStyles}>{text}</div>;
};

export default RewardBadge;
