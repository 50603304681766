import { FC, PropsWithChildren } from "react";

const ScreenLayout: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <div
      className={`fixed top-0 z-5 backdrop-blur-xl left-0 w-full h-full flex items-top justify-center bg-black bg-opacity-90 transition-opacity`}>
      <div className="absolute top-0 bg-gradient-to-b from-black to-transparent w-[100vw] h-[100px]" />
      <div className="absolute bottom-0 z-10 bg-gradient-to-t from-black to-transparent w-[100vw] h-[100px]" />
      {children}
    </div>
  );
};

export default ScreenLayout;
