import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css"; // Import the CSS file here

import { WagmiConfig, createClient, chain } from "wagmi";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import { MantineProvider } from "@mantine/core";
import { QueryClient, QueryClientProvider } from "react-query";
import { UserProvider } from "src/contexts/User.provider";

const wagmiClient = createClient(
  getDefaultClient({
    appName: "Bricks by Fair.xyz",
    infuraId: process.env.REACT_APP_INFURA_ID,
    //alchemyId:  process.env.REACT_APP_ALCHEMY_ID,
    chains: [chain.polygon],
  }),
);

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 0,
      refetchInterval: 5 * 1000,
    },
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement,
);

root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <ConnectKitProvider theme="auto">
        <MantineProvider withGlobalStyles withNormalizeCSS>
          <QueryClientProvider client={queryClient}>
            <UserProvider>
              <App />
            </UserProvider>
          </QueryClientProvider>
        </MantineProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  </React.StrictMode>,
);
