import React from "react";

interface Props {
  title: string;
  onClick?: () => void;
}

const NavigationTitleSection: React.FC<Props> = ({ title, onClick }) => {
  return (
    <div className="transition-all duration-1500">
      <div className="h-[10px] w-[1px] bg-white"></div>
      <button onClick={onClick}>
        <p
          style={{ fontFamily: "AkkuratMono" }}
          className="text-[12px]  pt-1 font-AM font-bold hover:opacity-20 text-white uppercase">
          {title}
        </p>
      </button>
    </div>
  );
};

export default NavigationTitleSection;
