import React from "react";
import RewardBadge from "./RewardBadge";
import classNames from "classnames";
import { Reward } from "../../../data/rewards";

interface RewardEntryProps {
  reward: Reward;
  onClickEntry: () => void;
  claimDisabled: boolean;
  claimBadgeLabel: string;
}

const RewardEntry: React.FC<RewardEntryProps> = ({
  reward,
  onClickEntry,
  claimDisabled,
  claimBadgeLabel,
}) => {
  return (
    <div
      onClick={onClickEntry}
      style={{
        backgroundColor: "rgba(77, 59, 59, 0.53)",
        borderColor: "rgba(255, 255, 255, 0.49)",
      }}
      className={`overflow-hidden flex gap-[12px] relative items-center w-full uppercase px-[32px] py-[16px] md:py-[18px] text-white cursor-pointer ${
        reward.entryType === "top"
          ? "rounded-t-2xl border"
          : reward.entryType === "bottom"
          ? "rounded-b-2xl border-b border-l border-r"
          : "border-b border-l border-r"
      }`}>
      <p className="text-[20px] md:text-[40px]">{reward.emoji}</p>
      <p className="font-am   text-ellipsis overflow-hidden truncate max-w-[30%] md:max-w-[70%]  text-[12px] md:text-[16px] pt-[6px] px-[16px]">
        {reward.rewardName}
      </p>
      <RewardBadge
        type="normal"
        text={`${reward.bricksRequired} BRICK${
          reward.bricksRequired === 1 ? "" : "S"
        }`}
      />
      <div className="hidden lg:flex flex-row items-center space-x-[12px]">
        {reward.newReward && <RewardBadge type="new" text="New" />}
        {reward.supply !== Infinity && (
          <RewardBadge type="limited" text="Limited Supply" />
        )}
      </div>
      <div
        className={classNames(
          "absolute right-[32px] py-[12px] px-[20px] text-[12px] md:text-[16px] rounded-xl font-primary font-bold bg-[#2C68F6] cursor-pointer",
          claimDisabled && "bg-opacity-30",
        )}>
        {claimBadgeLabel}
      </div>
    </div>
  );
};

export default RewardEntry;
