import { MainMediaAsset } from "src/modules/components/home/MainMediaAsset.component";
import "./index.css";
import { useCallback, useContext } from "react";
import { Screen, UserContext } from "src/contexts/User.context";
import { ConnectWalletButton } from "src/modules/components/layout/ConnectWalletButton.component";
import { AboutScreen } from "src/modules/screens/AboutScreen";
import { GiftScreen } from "src/modules/screens/GiftScreen";
import { LeaderboardScreen } from "src/modules/screens/LeaderboardScreen";
import { RedeemScreen } from "src/modules/screens/RedeemScreen";
import { BricksTitle } from "src/modules/components/layout/BricksTitle.component";
import { NavigationFooter } from "src/modules/components/layout/NavigationFooter.component";
import ScreenLayout from "src/modules/ScreenLayout";

function App() {
  const { setScreen, screen } = useContext(UserContext);

  const handleClickTitle = useCallback(() => {
    if (screen !== Screen.Home) setScreen(Screen.Home);
  }, [screen, setScreen]);

  const getContentScreen = useCallback(() => {
    switch (screen) {
      case Screen.About:
        return <AboutScreen />;
      case Screen.Gift:
        return <GiftScreen />;
      case Screen.Redeem:
        return <RedeemScreen />;
      case Screen.Leaderboard:
        return <LeaderboardScreen />;
      default:
        return null;
    }
  }, [screen]);

  return (
    <div className="bg-black h-[100vh] w-[100vw] flex justify-center items-center">
      <ConnectWalletButton />
      <MainMediaAsset />
      <BricksTitle
        onClickTitle={handleClickTitle}
        shouldReward={screen === Screen.Home}
      />
      <NavigationFooter />
      {screen !== Screen.Home && (
        <ScreenLayout>{getContentScreen()}</ScreenLayout>
      )}
    </div>
  );
}

export default App;
