export const MainMediaAsset: React.FC = () => {
  return (
    <video
      className="absolute h-full md:w-full object-cover"
      autoPlay
      loop
      muted>
      <source src="wen.mp4" type="video/mp4" />
      {/* You can add fallback sources here for different video formats */}
    </video>
  );
};
