import React, { useCallback } from "react";
import RewardBadge from "../.././components/redeem/RewardBadge";
import classNames from "classnames";
import { chain, useContractWrite } from "wagmi";
import { BRICKS_CONTRACT_ADDRESS } from "../../../contracts/utils/contract.constants";
import { Reward } from "../../../data/rewards";
import JSONABI from "../../../contracts/abi/FairxyzGifts.json";
const abi = JSONABI.abi;

interface RewardPopupProps {
  reward: Reward;
  onClose: () => void;
  claimDisabled: boolean;
  claimBadgeLabel: string;
}

const RewardPopup: React.FC<RewardPopupProps> = ({
  reward,
  onClose,
  claimDisabled,
  claimBadgeLabel,
}) => {
  const { write: claimReward } = useContractWrite({
    addressOrName: BRICKS_CONTRACT_ADDRESS,
    chainId: chain.polygon.id,
    contractInterface: abi,
    functionName: "claimReward",
    args: [reward.rewardId, reward.bricksRequired],
    mode: "recklesslyUnprepared",
    onSuccess: () => {
      onClose();
    },
  });

  const handleClaim = useCallback(() => {
    claimReward();
  }, [claimReward]);

  const handleDialogClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
  }, []);

  return (
    <div
      style={{ backgroundColor: "rgba(0, 0, 0, 0.95)" }}
      className="fixed top-0 left-0 z-10 w-full cursor-pointer h-full items-center justify-center flex"
      onClick={onClose}>
      <div
        style={{
          backgroundColor: "rgba(77, 59, 59, 0.53)",
          borderColor: "rgba(255, 255, 255, 0.49)",
        }}
        onClick={handleDialogClick}
        className="relative p-[24px] text-white border rounded-lg backdrop-blur-2xl w-[80%] max-w-[1000px] overflow-hidden pointe cursor-text z-11">
        <div className="flex flex-row items-center justify-end w-full gap-[16px]">
          {reward.newReward && <RewardBadge type="new" text="New" />}
          {reward.supply != null && (
            <RewardBadge type="limited" text="Limited Supply" />
          )}
          <RewardBadge
            type="normal"
            text={`${reward.bricksRequired} BRICK${
              reward.bricksRequired === 1 ? "" : "S"
            }`}
          />
        </div>
        <div className="font-am opacity-50 hidden lg:flex uppercase  flex-col items-end text-[14px] absolute right-[24px] pt-[16px]">
          <p className="">Bricks Required | {reward.bricksRequired}</p>
          {reward.limit !== Infinity && (
            <p>
              Your Remaining Claims |{" "}
              {reward.limit
                ? `${reward.limit}, Renews ${reward?.renews ?? ""}`
                : `0, Renews ${reward.renews}`}
            </p>
          )}
          {reward.supply !== Infinity && (
            <p className="">Global Supply | {reward.supply}</p>
          )}
        </div>
        <div className="flex flex-row items-start  text-[40px]  leading-[50px]   md:text-[55px]  md:leading-[60px]  pt-[16px] w-[100%] lg:w-[60%] uppercase font-am ">
          <p>{reward.emoji}</p>
          <p className="pl-[30px] " id="modal-title">
            {reward.rewardName}
          </p>
        </div>
        <p className="font-primary font-semibold pt-[40px]">
          {reward.description}
        </p>
        <button
          className={classNames(
            "w-full py-[16px] mt-[32px] px-[20px] text-[16px] rounded-lg bg-[#2C68F6] font-primary font-bold",
            !claimDisabled
              ? "hover:bg-opacity-10"
              : "bg-opacity-30 cursor-not-allowed",
          )}
          onClick={handleClaim}>
          {claimBadgeLabel}
        </button>
      </div>
    </div>
  );
};

export default RewardPopup;
