export type Reward = {
  idx: number;
  emoji: string;
  entryType?: "top" | "bottom";
  rewardName: string;
  bricksRequired: number;
  limit: number;
  supply: number;
  rewardId: string;
  renews: string | null;
  description: string;
  newReward?: boolean;
};

export const rewardsList: Reward[] = [
  {
    idx: 0,
    emoji: "🏝️",
    rewardName: "Half Day Off",
    bricksRequired: 11,
    rewardId: "HOLIDAY",
    renews: "monthly",
    description:
      "A half day of paid leave. Ideal for some personal time or to unwind.",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 1,
    emoji: "🏞️",
    rewardName: "Day Off",
    bricksRequired: 22,
    rewardId: "DOFF",
    renews: "quarterly",
    description:
      "A full day of paid leave. Take a break, you deserve it. Get up to whatever you want",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 2,
    emoji: "🧬",
    rewardName: "Clone X",
    bricksRequired: 285,
    rewardId: "CLONEX",
    renews: null,
    description:
      "A Clone X NFT, straight to your wallet (assuming £4,300 equivalent value). Look Fresh",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 3,
    emoji: "🏖️",
    rewardName: "Weekend in Europe",
    bricksRequired: 70,
    rewardId: "EUROPE",
    renews: null,
    description:
      "A weekend trip to Europe. Experience diverse cultures, food and views (£800 value)",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 4,
    emoji: "🎩",
    rewardName: "Founders Dress up",
    bricksRequired: 3,
    rewardId: "DRESSUP",
    renews: null,
    description:
      "Choose a founder of your choice to dress up in ANY costume to the office (up to 50 pounds). Fun, laughter, and possibly, wigs.",
    limit: Infinity,
    supply: 3,
  },

  {
    idx: 5,
    emoji: "🧘",
    rewardName: "Life Coach Session",
    bricksRequired: 10,
    rewardId: "LIFECOACH",
    renews: "monthly",
    description:
      "Empower your personal and professional life with a session from a life coach.",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 6,
    emoji: "🤲",
    rewardName: "Charity Donation",
    bricksRequired: 3,
    rewardId: "CHARITY",
    renews: null,
    description:
      "Make a difference. A charity donation will be made in your name (£50 value)",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 7,
    emoji: "🎧",
    rewardName: "Airpod Pros",
    bricksRequired: 17,
    rewardId: "AIRPOD",
    renews: null,
    description:
      "Listen in style and comfort with a brand new pair of Airpod Pros",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 8,
    emoji: "☕",
    rewardName: "Pret Subscription for a month",
    bricksRequired: 2,
    rewardId: "PRET",
    renews: "monthly",
    description:
      "Enjoy a month-long subscription to Pret. Coffees, sandwiches, and more!",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 9,
    emoji: "🎡",
    rewardName: "Wheel Spin",
    bricksRequired: 20,
    rewardId: "WHEELSPIN",
    renews: "quarterly",
    description:
      "Take a chance with a spin of the wheel. Who knows what you might win!",
    limit: 1,
    supply: Infinity,
  },

  {
    idx: 10,
    emoji: "🍽️",
    rewardName: "Dinner for 2",
    bricksRequired: 8,
    rewardId: "DINNER",
    renews: "quarterly",
    description:
      "Treat yourself and a loved one to a delightful dinner on us (£150 value)",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 11,
    emoji: "🏰",
    rewardName: "Soho House Membership (if you get in)",
    bricksRequired: 60,
    rewardId: "SOHOHOUSE",
    renews: "yearly",
    description:
      "Get a Soho House Membership, a place to relax, meet people, and enjoy.",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 12,
    emoji: "🏖️",
    rewardName: "Work from a Beach Resort (week-long accommodation)",
    bricksRequired: 130,
    rewardId: "BEACHRESORT",
    renews: "yearly",
    description:
      "Change your working view with a week-long stay at a beach resort (£2,500 value)",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 13,
    emoji: "🎁",
    rewardName: "Mystery Box",
    bricksRequired: 10,
    rewardId: "MYSTERYBOX",
    renews: "monthly",
    description: "A box full of surprises! Discover new items each month.",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 41,
    emoji: "🍔",
    rewardName: "Double lunch allowance",
    entryType: "top",
    bricksRequired: 1,
    rewardId: "DOUBLELUNCH",
    renews: "monthly",
    description:
      "Enjoy a hearty meal with a double lunch allowance! (£10 value)",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 14,
    emoji: "🌳",
    rewardName: "Plant a Tree in your name",
    bricksRequired: 1,
    rewardId: "PLANTATREE",
    renews: "yearly",
    description: "Help the environment. We'll plant a tree in your name.",
    limit: 1,
    supply: Infinity,
  },

  {
    idx: 15,
    emoji: "🛀",
    rewardName: "AIRE Ancient Baths for two",
    bricksRequired: 15,
    rewardId: "AIREBATHS",
    renews: null,
    description:
      "Experience relaxation at its finest with a visit to AIRE Ancient Baths.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 16,
    emoji: "⌚",
    rewardName: "Rolex",
    bricksRequired: 467,
    rewardId: "ROLEX",
    renews: null,
    description: "Luxury at your wrist. Get a stunning Rolex watch",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 17,
    emoji: "🎨",
    rewardName: "Chromie Squiggle",
    bricksRequired: 1108,
    rewardId: "CHROMIE",
    renews: null,
    description:
      "Own a Chromie Squiggle, a unique piece of digital art (assuming £17,000 equivalent value)",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 18,
    emoji: "🎎",
    rewardName: "Custom Bobblehead",
    bricksRequired: 4,
    rewardId: "BOBBLEHEAD",
    renews: "yearly",
    description:
      "Add some fun to your desk with a custom bobblehead of your choice.",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 19,
    emoji: "🪴",
    rewardName: "Huge Houseplant",
    bricksRequired: 7,
    rewardId: "HOUSEPLANT",
    renews: null,
    description: "Brighten up your space with a huge, beautiful houseplant.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 20,
    emoji: "🎵",
    rewardName: "Choose Office Background Music for a Day",
    bricksRequired: 2,
    rewardId: "MUSICDAY",
    renews: "quarterly",
    description: "Be the office DJ for a day! You choose the background tunes.",
    limit: 1,
    supply: 10,
  },
  {
    idx: 21,
    emoji: "🏢",
    rewardName: "Choose the name of the Conference Room",
    bricksRequired: 10,
    rewardId: "CONFROOM",
    renews: null,
    description: "Put your mark on the office. Name the conference room!",
    limit: Infinity,
    supply: 1,
  },
  {
    idx: 22,
    emoji: "🛍️",
    rewardName: "Personal Shopping with Ray",
    bricksRequired: 27,
    rewardId: "SHOPRAY",
    renews: "yearly",
    description:
      "Enjoy a personal shopping experience with Ray. Style upgrade guaranteed (£400 value)",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 26,
    emoji: "🪂",
    rewardName: "Sky Dive",
    bricksRequired: 20,
    rewardId: "SKYDIVE",
    renews: null,
    description:
      "For the thrill-seekers. Experience the adrenaline rush of a sky dive.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 27,
    emoji: "🌴",
    rewardName: "Extra 5 days holiday",
    bricksRequired: 132,
    rewardId: "EXTRAHOLIDAY",
    renews: "yearly",
    description:
      "Who doesn't love a longer break? Enjoy an extra 5 days of holiday.",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 28,
    emoji: "🏨",
    rewardName: "Hotel stay for 2 in London 2 night",
    bricksRequired: 40,
    rewardId: "HOTELSTAY",
    renews: null,
    description:
      "Take a luxury break in London with a 2-night stay at a top hotel (£600 value)",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 29,
    emoji: "🍱",
    rewardName: "Hello Fresh for 3 months",
    bricksRequired: 8,
    rewardId: "HELLOFRESH",
    renews: null,
    description:
      "Enjoy 3 months of delicious, easy-to-cook meals with Hello Fresh.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 30,
    emoji: "📺",
    rewardName: "Sick new TV",
    bricksRequired: 40,
    rewardId: "NEWTVC",
    renews: null,
    description: "Upgrade your entertainment with a brand new TV (£600 value)",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 31,
    emoji: "🚄",
    rewardName: "Eurostar voucher",
    bricksRequired: 13,
    rewardId: "EUROSTAR",
    renews: null,
    description:
      "Plan a getaway! Enjoy a trip with this Eurostar voucher (£200 value)",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 32,
    emoji: "🍳",
    rewardName: "Ninja air fryer",
    bricksRequired: 15,
    rewardId: "AIRFRYER",
    renews: null,
    description: "Cook delicious meals easily with a Ninja air fryer.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 33,
    emoji: "💳",
    rewardName: "Voucher of your choice",
    bricksRequired: 7,
    rewardId: "CHOICEVOUCHER",
    renews: null,
    description:
      "Choose a voucher for your favourite retailer or experience (£100 value)",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 34,
    emoji: "🏋️",
    rewardName: "Class pass for 1 year",
    bricksRequired: 32,
    rewardId: "CLASSPASS",
    renews: null,
    description:
      "Stay fit with a one-year class pass for a variety of workouts",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 35,
    emoji: "🏋️‍♀️",
    rewardName: "Gym membership for 1 year - 1Rebel, Barrys bootcamp etc",
    bricksRequired: 73,
    rewardId: "GYMMEMBERSHIP",
    renews: null,
    description:
      "Keep fit with a 1-year gym membership at top fitness studios.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 36,
    emoji: "🛒",
    rewardName: "Your grocery shop paid for 6 months",
    bricksRequired: 160,
    rewardId: "GROCERYSHOP",
    renews: "yearly",
    description:
      "Ease your budget with 6 months of paid grocery shopping (1 shop per week, £100 value)",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 37,
    emoji: "💻",
    rewardName: "A Macbook (personal)",
    bricksRequired: 147,
    rewardId: "MACBOOK",
    renews: null,
    description: "Upgrade your tech with a new personal MacBook.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 38,
    emoji: "📱",
    rewardName: "New iPhone when it is released",
    bricksRequired: 60,
    rewardId: "NEWIPHONE",
    renews: null,
    description: "Get the latest iPhone as soon as it's released.",
    limit: Infinity,
    supply: Infinity,
  },
  {
    idx: 39,
    emoji: "☕",
    rewardName: "Founder buys everyone coffee for the day",
    bricksRequired: 2,
    rewardId: "COFFEEDAY",
    renews: "quarterly",
    description: "Everyone enjoys a coffee on the founder. Perfect pick-me-up!",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 40,
    emoji: "🥐",
    rewardName: "Breakfast delivery to home/office",
    bricksRequired: 1,
    rewardId: "BREAKFASTDELIVERY",
    renews: "monthly",
    description:
      "Start your day right with a breakfast delivery to your home or office (£20 value)",
    limit: 1,
    supply: Infinity,
  },
  {
    idx: 42,
    emoji: "🍺",
    rewardName: "6 x Artisan Beers",
    bricksRequired: 1,
    rewardId: "BEER",
    renews: "monthly", // Adjust according to requirements
    description:
      "Enjoy 6 artisan beers. Ideal for relaxing after a hard day's work.",
    limit: 1, // Adjust according to requirements
    supply: Infinity,
  },
  {
    idx: 43,
    emoji: "🍫",
    rewardName: "Box of Chocolates",
    bricksRequired: 1,
    rewardId: "CHOCO",
    renews: "monthly", // Adjust according to requirements
    description:
      "A box of delicious chocolates. Treat yourself or share with friends.",
    limit: 1, // Adjust according to requirements
    supply: Infinity,
  },
  {
    idx: 44,
    emoji: "🎟️",
    rewardName: "Cinema Tickets for Two",
    bricksRequired: 1,
    rewardId: "CINEMA",
    renews: "monthly", // Adjust according to requirements
    description:
      "Enjoy a movie night with someone special with these cinema tickets for two.",
    limit: 1, // Adjust according to requirements
    supply: Infinity,
  },
  {
    idx: 45,
    emoji: "🛀",
    rewardName: "Aromatic Bath Bombs",
    bricksRequired: 1,
    rewardId: "BATHBOMBS",
    renews: "monthly", // Adjust according to requirements
    description: "Relax and unwind with these aromatic bath bombs.",
    limit: 1, // Adjust according to requirements
    supply: Infinity,
  },
];
