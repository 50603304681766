import React, { useContext } from "react";
import LeaderboardEntry from "../components/leaderboard/LeaderboardEntry";
import { numberToPosition } from "../../utils";
import { useAccount } from "wagmi";
import { UserContext } from "src/contexts/User.context";
import { isNotFounder } from "src/data/employee";

type Props = {};

export const LeaderboardScreen: React.FC<Props> = () => {
  const { address } = useAccount();
  const { employeeData, currentEmployee } = useContext(UserContext);
  const stringPosition = numberToPosition(currentEmployee?.leaderboardPosition);

  // Filter out Founders
  const filteredEmployeeData = employeeData.filter(isNotFounder);

  return (
    <div className="h-full  w-full pt-[150px] overflow-scroll text-white  px-[40px] md:px-[80px] lg:max-w-[2000px] lg:px-[15%]">
      <div className="flex flex-row flex-wrap items-center w-full justify-between mb-[20px]   gap-[20px]   ">
        <div className="flex flex-row flex-wrap  items-center justify-between w-full">
          <h1 className="font-pressura_text  uppercase text-[40px] pr-[40px]">
            Leaderboard
          </h1>
          <div className="my-[20px] md:my-0 w-[320px] text-[16px] font-bold py-[8px]   font-primary  uppercase text-center text-white flex flex-row flex-wrap items-center justify-center rounded-xl   border-[3px] backdrop-blur">
            <p>{`YOU ARE IN ${stringPosition} PLACE`}</p>
          </div>
        </div>
        <p className="-mt-[8px] mb-[12px] md:w-[60%] text-[16px] leading-[20px] font-light font-primary">
          Climb to the top! Check out the leaderboard to see where you rank
          amongst the Fair.xyz squad
        </p>

        <div className="w-full">
          <div className="flex flex-row items-center justify-end text-[12px] pb-[12px] text-white gap-[80px] w-full font-am uppercase">
            <p className="hidden xl:block w-[95px]">Department</p>
            <p className="hidden md:block w-[80px]">Available</p>
            <p className="pr-[32px] w-[115px]">Total Earnt</p>
          </div>
          <div className="w-full overflow-scroll pb-[200px]">
            {filteredEmployeeData.map((employee, index) => (
              <LeaderboardEntry
                key={employee.value}
                entryType={
                  index === 0
                    ? "top"
                    : index === employeeData.length - 1
                    ? "bottom"
                    : ""
                }
                position={index + 1}
                name={employee.label}
                department={employee.description}
                redeemableBricks={employee.redeemableBricks || 0}
                totalEarntBricks={employee.totalEarntBricks || 0}
                me={employee.value === address}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
