import React, { useCallback, useContext, useState, useEffect } from "react";
import { forwardRef } from "react";
import { Group, Text, Select } from "@mantine/core";
import { isNotFounder, nonFounders } from "../../data/employee";
import classNames from "classnames";
import {
  chain,
  useAccount,
  useContractWrite,
  useWaitForTransaction,
} from "wagmi";
import { BRICKS_CONTRACT_ADDRESS } from "../../contracts/utils/contract.constants";
import JSONABI from "../../contracts/abi/FairxyzGifts.json";
import { UserContext } from "src/contexts/User.context";
import { openInNewTab } from "src/utils";
const abi = JSONABI.abi;

const BRICKS_CHANNEL_ID = "C05CGG8H1RQ";

const selectStyles = {
  label: {
    color: "#EFD8D8",
    fontFamily: "FAIR-XYZ, monospace",
    fontWeight: 400,

    paddingBottom: "5px",
    fontSize: "14px",
  },
  input: {
    fontFamily: "FAIR-XYZ",
    fontWeight: 600,
    height: "50px",
  },
  dropdown: {
    borderRadius: 0,
  },
  item: {
    borderRadius: 0,
    "&[data-active]": {},
  },
};

interface ItemProps extends React.ComponentPropsWithoutRef<"div"> {
  image: string;
  label: string;
  description: string;
}

const SelectItem = forwardRef<HTMLDivElement, ItemProps>(
  ({ image, label, description, ...others }: ItemProps, ref) => (
    <div ref={ref} {...others}>
      <Group noWrap>
        <div style={{ fontFamily: "AkkuratMono" }}>
          <p style={{ fontFamily: "AkkuratMono" }} className="text-black">
            {label}
          </p>
          <Text className="text-black  font-primary" size="xs" opacity={0.65}>
            {description}
          </Text>
        </div>
      </Group>
    </div>
  ),
);

export const GiftScreen: React.FC = () => {
  const { address: currentUserWalletAddress } = useAccount();
  const { currentEmployee, refreshCurrentEmployee } = useContext(UserContext);
  const [selectedEmployeeAddress, setSelectedEmployeeAddress] =
    useState<string>();
  const [isSlackMessageSent, setIsSlackMessageSent] = useState(false);

  const {
    isLoading: isWriting,
    write: giftBrick,
    data: writeData,
    error: contractError,
    isSuccess: isGiftBrickSuccess,
  } = useContractWrite({
    addressOrName: BRICKS_CONTRACT_ADDRESS,
    chainId: chain.polygon.id,
    contractInterface: abi,
    functionName: "gift",
    args: [selectedEmployeeAddress],
    mode: "recklesslyUnprepared",
    onSuccess: refreshCurrentEmployee,
  });

  const waitForTransaction = useWaitForTransaction({
    hash: writeData?.hash,
  });

  const isLoading = isWriting || waitForTransaction.isLoading;

  const [customError, setCustomError] = useState<string | null>(null); // New state for error

  const handleSelectEmployee = useCallback((value: string) => {
    setCustomError(null); // clear previous error message
    setIsSlackMessageSent(false);
    setSelectedEmployeeAddress(value);
  }, []);

  const handleSendBrick = useCallback(() => {
    if (!selectedEmployeeAddress) return;
    if (isGiftBrickSuccess && !isSlackMessageSent) {
      openInNewTab(
        `https://slack.com/app_redirect?channel=${BRICKS_CHANNEL_ID}`,
      );
      setIsSlackMessageSent(true);
    } else {
      setCustomError(null); // clear previous error message
      setIsSlackMessageSent(false);
      giftBrick();
    }
  }, [
    giftBrick,
    isGiftBrickSuccess,
    isSlackMessageSent,
    selectedEmployeeAddress,
  ]);

  useEffect(() => {
    if (contractError) {
      if (contractError.message.includes("gifts to the same employee")) {
        setCustomError(
          "PLEASE WAIT 1 WEEK FROM YOUR LAST GIFT TO THIS EMPLOYEE",
        );
      }
    }
  }, [contractError]);

  const remainingGifts = !isNotFounder(currentEmployee)
    ? "UNLIMITED"
    : currentEmployee?.availableBricks ?? 0;

  const buttonDisabled =
    remainingGifts === 0 ||
    isLoading ||
    !selectedEmployeeAddress ||
    customError;

  const getButtonLabel = () => {
    if (isLoading) return "SENDING ⏳";
    if (!remainingGifts) return "YOU HAVE NO MORE CLAIMS";
    if (!selectedEmployeeAddress) return "SELECT A FAIR.XYZ PIONEER";
    if (isGiftBrickSuccess && !isSlackMessageSent)
      return "SEND A SLACK MESSAGE";

    return "SEND BRICK";
  };

  const employeesThatCanReceiveBricks = nonFounders.filter(
    employee => employee.value !== currentUserWalletAddress,
  );

  return (
    <div className="h-full  w-full pt-[250px] overflow-scroll text-white  px-[40px] md:px-[80px] lg:max-w-[2000px] lg:px-[15%]">
      <div className="flex flex-row flex-wrap  items-center mb-[32px] justify-between w-full">
        <h1 className="font-pressura_text  uppercase text-[40px] pr-[40px]">
          Gift a brick
        </h1>
        <div className="my-[20px] lg:my-0 w-[320px] text-[16px] font-bold py-[8px]   font-primary  uppercase text-center text-white flex flex-row flex-wrap items-center justify-center rounded-xl  border-[3px] backdrop-blur">
          <p>{`YOU HAVE ${remainingGifts} BRICK${
            remainingGifts === 1 ? "" : "S"
          } TO GIVE`}</p>
        </div>
      </div>
      <Select
        styles={selectStyles}
        label="Select a Fair.xyz pioneer"
        placeholder="Pick one"
        itemComponent={SelectItem}
        data={employeesThatCanReceiveBricks}
        maxDropdownHeight={250}
        onChange={handleSelectEmployee}
        filter={(value, item) =>
          // item.label.toLowerCase().includes(value.toLowerCase().trim()) ||
          item.description.toLowerCase().includes(value.toLowerCase().trim())
        }
      />
      <button
        className={classNames(
          "w-full py-[16px] mt-[64px] px-[20px] text-[16px] rounded-lg bg-[#2C68F6] font-primary font-bold",
          buttonDisabled
            ? "bg-opacity-30 cursor-not-allowed"
            : "hover:bg-opacity-10",
        )}
        onClick={handleSendBrick}>
        {customError ? customError : getButtonLabel()}
      </button>
    </div>
  );
};
