import React from "react";
import RewardBadge from "../redeem/RewardBadge";

interface LeaderboardEntryProps {
  department: string;
  position: number;
  name: string;
  redeemableBricks: number;
  totalEarntBricks: number;
  entryType?: string;
  me?: boolean;
}

const LeaderboardEntry: React.FC<LeaderboardEntryProps> = ({
  department,
  position,
  name,
  redeemableBricks,
  totalEarntBricks,
  entryType = "",
  me = false,
}) => {
  // Function to replace position with emoji
  const getMedalEmoji = (position: number) => {
    if (position === 1) {
      return "🥇";
    } else if (position === 2) {
      return "🥈";
    } else if (position === 3) {
      return "🥉";
    } else {
      return position;
    }
  };

  return (
    <div
      style={{
        backgroundColor: me ? "#2663FF" : "rgba(77, 59, 59, 0.53)",
        borderColor: "rgba(255, 255, 255, 0.49)",
      }}
      className={`overflow-hidden relative flex gap-[12px] items-center justify-between w-full uppercase px-[32px] py-[16px] text-white ${
        entryType === "top"
          ? "rounded-t-2xl border"
          : entryType === "bottom"
          ? "rounded-b-2xl border-b border-l border-r"
          : "border-b border-l border-r"
      }`}>
      <div className="flex flex-row items-center gap-[10px]">
        <div
          className={`flex items-center justify-center border-white   rounded-full ${
            position === 1 || position === 2 || position === 3
              ? "px-[6px] text-[32px]"
              : "text-[20px] border-[3px] px-[12px] py-[2px]"
          }`}>
          {" "}
          <p className="">{getMedalEmoji(position)}</p>
        </div>

        <p className="font-am  text-[12px] sm:text-[16px] pt-[6px] text-ellipsis overflow-hidden truncate max-w-[200px] md:max-w-[250px] px-[16px]">
          {name}
        </p>
      </div>

      <div className="flex flex-row items-center gap-[80px]">
        <p className="pt-[6px] px-[16px] hidden xl:block font-primary font-semibold w-[100px]">
          {department}
        </p>
        <div className="hidden md:block w-[85px]">
          <RewardBadge
            type="normal"
            text={`${redeemableBricks} BRICK${
              redeemableBricks === 1 ? "" : "S"
            }`}
          />
        </div>
        <div className="w-[85px]">
          <RewardBadge
            type="white"
            text={`${totalEarntBricks} BRICK${
              totalEarntBricks === 1 ? "" : "S"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default LeaderboardEntry;
