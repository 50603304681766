import { createContext } from "react";
import { CurrentEmployee, Employee } from "src/data/employee";
import { Reward } from "src/data/rewards";

export enum Screen {
  Home,
  About,
  Redeem,
  Leaderboard,
  Gift,
}

type UserContextValue = {
  screen: Screen;
  setScreen: (screen: Screen) => void;
  employeeData: Employee[];
  rewardData: Reward[];
  currentEmployee?: CurrentEmployee;
  refreshCurrentEmployee: () => void;
  refreshRewardDataForCurrentEmployee: () => void;
};

export const UserContext = createContext<UserContextValue>({
  screen: Screen.Home,
  setScreen: () => {},
  employeeData: [],
  rewardData: [],
  currentEmployee: undefined,
  refreshCurrentEmployee: () => {},
  refreshRewardDataForCurrentEmployee: () => {},
});
