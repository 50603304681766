import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Screen, UserContext } from "./User.context";
import { CurrentEmployee, Employee } from "src/data/employee";
import { Reward } from "src/data/rewards";
import { useAccount } from "wagmi";
import {
  generateBricksForCurrentUser,
  generateBricksForEmployees,
  generateRewardData,
} from "src/utils";

export const UserProvider: React.FC<React.PropsWithChildren<{}>> = ({
  children,
}) => {
  const { address: currentUserAddress } = useAccount();
  const [screen, setScreen] = useState<Screen>(Screen.Home);
  const [employeeData, setEmployeeData] = useState<Employee[]>([]);
  const [rewardData, setRewardData] = useState<Reward[]>([]);
  const [currentEmployee, setCurrentEmployee] = useState<CurrentEmployee>();

  const refreshCurrentEmployeeData = useCallback(async () => {
    const currentUserData = await generateBricksForCurrentUser(
      currentUserAddress,
    );
    if (!currentUserData) return;
    setCurrentEmployee({
      ...currentUserData,
      leaderboardPosition: currentEmployee?.leaderboardPosition,
    });
  }, [currentEmployee?.leaderboardPosition, currentUserAddress]);

  const refreshRewardDataForCurrentEmployee = useCallback(async () => {
    const rewardData = await generateRewardData(currentUserAddress);
    setRewardData(rewardData);
  }, [currentUserAddress]);

  const fetchAllEmployeeData = useCallback(async () => {
    const employeeData = await generateBricksForEmployees();
    setEmployeeData(employeeData);
    const currentEmployee = employeeData.findIndex(
      employee => employee.value === currentUserAddress,
    );
    if (currentEmployee !== undefined)
      setCurrentEmployee({
        ...employeeData[currentEmployee],
        leaderboardPosition: currentEmployee + 1,
      });
  }, [currentUserAddress]);

  useEffect(() => {
    const generateData = async () => {
      await fetchAllEmployeeData();
      await refreshRewardDataForCurrentEmployee();
    };
    generateData();
  }, [fetchAllEmployeeData, refreshRewardDataForCurrentEmployee]);

  useEffect(() => {
    const interval = setInterval(refreshCurrentEmployeeData, 30 * 1000); // Refresh current employee every 30 seconds
    return () => clearInterval(interval);
  });

  const memoisedValues = useMemo(
    () => ({
      screen,
      setScreen,
      employeeData,
      rewardData,
      currentEmployee,
      refreshCurrentEmployee: refreshCurrentEmployeeData,
      refreshRewardDataForCurrentEmployee,
    }),
    [
      currentEmployee,
      employeeData,
      refreshCurrentEmployeeData,
      refreshRewardDataForCurrentEmployee,
      rewardData,
      screen,
    ],
  );

  return (
    <UserContext.Provider value={memoisedValues}>
      {children}
    </UserContext.Provider>
  );
};
