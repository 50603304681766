import { useReward } from "react-rewards";

type Props = { onClickTitle: () => void; shouldReward: boolean };

export const BricksTitle: React.FC<Props> = ({
  onClickTitle,
  shouldReward,
}) => {
  const { reward, isAnimating } = useReward("rewardId", "emoji", {
    emoji: ["🧱"],
    angle: 340,
    elementCount: 5,
    lifetime: 150,
    startVelocity: 40,
  });
  const handleClick = () => {
    onClickTitle();
    shouldReward && reward();
  };
  return (
    <button
      disabled={isAnimating}
      onClick={handleClick}
      className="absolute z-10 flex flex-col items-start left-[10%] top-[30px] text-[40px]">
      <span id="rewardId" />
      <h1 style={{ fontFamily: "pressura" }} className="text-[40px] text-white">
        BRICKS
      </h1>
      <h2
        style={{ fontFamily: "AkkuratMono" }}
        className="text-[12px] ml-[2px] mt-[2px] font-AM font-bold text-white uppercase">
        by fair.xyz
      </h2>
    </button>
  );
};
