import TitleHeader from "./NavigationTitleSection";
import { useCallback, useContext } from "react";
import { Screen, UserContext } from "src/contexts/User.context";
import { useAccount } from "wagmi";

export const NavigationFooter: React.FC = () => {
  const { address } = useAccount();
  const { setScreen, employeeData, rewardData, currentEmployee } =
    useContext(UserContext);

  const handleSetAboutScreen = useCallback(
    () => setScreen(Screen.About),
    [setScreen],
  );
  const handleSetGiftScreen = useCallback(
    () => setScreen(Screen.Gift),
    [setScreen],
  );
  const handleSetRedeemScreen = useCallback(
    () => setScreen(Screen.Redeem),
    [setScreen],
  );
  const handleSetLeaderboardScreen = useCallback(
    () => setScreen(Screen.Leaderboard),
    [setScreen],
  );

  if (!address || !currentEmployee || !rewardData.length || !employeeData)
    return null;

  return (
    <div className="absolute z-10 bottom-10 items-center justify-center w-[80vw] ">
      <div className="h-[1px] w-[80vw] bg-white"></div>
      <div className="flex flex-row  w-[80%] items-center justify-between">
        <TitleHeader title="About" onClick={handleSetAboutScreen} />
        <TitleHeader title="gift" onClick={handleSetGiftScreen} />
        <TitleHeader title="redeem" onClick={handleSetRedeemScreen} />
        <TitleHeader title="leaderboard" onClick={handleSetLeaderboardScreen} />
      </div>
    </div>
  );
};
