import { Employee } from "./data/employee";
import { employeeList } from "./data/employee";
import {
  getNumberOfRedeemableGifts,
  getReceivedGifts,
  getRemainingGifts,
  viewRewardsClaimed,
} from "./contracts/utils/contract.utils";
import { Reward, rewardsList } from "./data/rewards";

export const generateBricksForEmployees = async (): Promise<Employee[]> => {
  const [allRedeemableBricks, allEarntBricks, allAvailableBricks] =
    await Promise.all([
      Promise.all(
        employeeList.map(employee =>
          getNumberOfRedeemableGifts(employee.value),
        ),
      ),
      Promise.all(
        employeeList.map(employee => getReceivedGifts(employee.value)),
      ),
      Promise.all(
        employeeList.map(employee => getRemainingGifts(employee.value)),
      ),
    ]);
  return employeeList
    .map((employee, idx) => {
      const availableBricks = allAvailableBricks[idx];
      const redeemableBricks = allRedeemableBricks[idx];
      const totalEarntBricks = allEarntBricks[idx];
      return {
        ...employee,
        availableBricks,
        redeemableBricks,
        totalEarntBricks,
      };
    })
    .sort((a, b) => (b.totalEarntBricks || 0) - (a.totalEarntBricks || 0));
};

export const generateRewardData = async (
  address?: string,
): Promise<Reward[]> => {
  if (!address) return [];
  const allRewards = await Promise.all(
    rewardsList.map(reward => viewRewardsClaimed(address, reward.rewardId)),
  );

  let sortedRewardsList = rewardsList.sort(
    (a, b) => a.bricksRequired - b.bricksRequired,
  );

  return sortedRewardsList.map((reward, idx) => {
    const rewardLimit = reward.limit;
    const userClaimed = allRewards[idx]?.userClaimed || 0;

    const totalSupply = reward.supply;
    const totalClaimed = allRewards[idx]?.totalClaimed || 0;

    return {
      ...reward,
      limit: Math.max(rewardLimit - userClaimed, 0),
      supply: Math.max(totalSupply - totalClaimed, 0),
    };
  });
};

export const generateBricksForCurrentUser = async (
  address?: string,
): Promise<Employee | undefined> => {
  const currentUser = employeeList.find(employee => employee.value === address);
  if (!currentUser) return;
  const [redeemableBricks, totalEarntBricks, availableBricks] =
    await Promise.all([
      getNumberOfRedeemableGifts(currentUser.value),
      getReceivedGifts(currentUser.value),
      getRemainingGifts(currentUser.value),
    ]);

  return {
    ...currentUser,
    availableBricks,
    redeemableBricks,
    totalEarntBricks,
  };
};

export const numberToPosition = (number?: number): string => {
  if (!number) return "";
  if (number === 1) return "1st";
  if (number === 2) return "2nd";
  if (number === 3) return "3rd";
  return `${number}th`;
};

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};
