import React from "react";

export const AboutScreen: React.FC = () => {
  return (
    <div className="pt-[200px] overflow-scroll no-scrollbar text-white pb-[200px] px-[80px] md:px-[160px] lg:px-[15%] lg:max-w-[1200px] ">
      <h1
        style={{ fontFamily: "pressura" }}
        className="leading-[50px] uppercase text-[40px]">
        At Fair.xyz, we are redefining the future, so it is only right we
        redefine culture.
      </h1>
      <p style={{ fontFamily: "AkkuratMono" }} className="pt-[40px]">
        At Fair.xyz, we are embracing the infinite possibilities of the digital
        age to revolutionize the way we appreciate, celebrate, and reward our
        most valuable asset: our employees. We introduce BRICKS, an initiative
        that combines the vibrant potential of Non-Fungible Tokens (NFTs) with
        the time-honored value of recognizing excellence. This isn't just about
        novelty, it's about pioneering a new culture that inspires everyone to
        reach for their greatest potential and ensures that good work doesn't go
        unnoticed.
      </p>
      <p style={{ fontFamily: "AkkuratMono" }} className="pt-[40px]">
        BRICKS isn't a reward system, it's a testament to our collective
        achievements. Each BRICKS token signifies an accomplishment, an extra
        mile journeyed, or a valuable contribution to our common cause. Our
        employees have the unique privilege of rewarding their peers for
        commendable work with these NFTs. Even our founders and managers join in
        this shared endeavor of appreciation. And it doesn't stop there; we've
        made room for fun, creativity, and games as novel avenues to earn
        BRICKS, creating an engaging, fulfilling, and truly dynamic work
        environment.
      </p>
      <p style={{ fontFamily: "AkkuratMono" }} className="pt-[40px]">
        What makes BRICKS truly innovative is the power it hands back to our
        employees. Not only are they tokens of recognition, but they also hold
        tangible value. Employees can "burn" their BRICKS to claim various
        exciting rewards—from well-deserved days off to thrilling prizes and
        even the delightfully humorous opportunity to see the founders don
        unique outfits! These rewards reflect the essence of our unique culture:
        valuing hard work, promoting balance, and cherishing a sense of humor.
      </p>
      <p style={{ fontFamily: "AkkuratMono" }} className="pt-[40px]">
        At Fair.xyz, we're not just talking about change; we are implementing
        it. We believe that the future of work culture lies in empowering
        employees, promoting collaboration, and making the workplace a hub of
        innovation and engagement. BRICKS is our first step towards this future,
        and together, we will build a workplace that isn't just about getting
        the job done, but about celebrating each other and the journey we're on.
        Join us in this venture as we redefine culture, one BRICK at a time.
      </p>
    </div>
  );
};
